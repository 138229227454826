import { useTranslation } from "react-i18next";
import { useInfoModal } from "../Context/InfoModalProvider";
import { useRef } from "react";
import Compressor from "compressorjs";

interface ImageInputProps {
  images: File[];
  setImages: CallableFunction;
  title?: string | null;
  multiple?: boolean;
  required?: boolean;
  preview?: boolean;
}

const ImageInput: React.FunctionComponent<ImageInputProps> = ({
  images,
  setImages,
  title,
  multiple = false,
  required = true,
  preview = true,
}) => {
  const { t } = useTranslation();
  const { showInfo } = useInfoModal();

  const idRef = useRef<string>(Math.random().toString(36).substring(2));
  const inputRef = useRef<HTMLInputElement | null>(null);

  const removeImage = (index: number) => {
    let newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    if (newImages.length === 0 && inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const imageOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);

      const smallFiles = filesArray.filter((x) => x.size / 1000 / 1000 <= 40);

      if (filesArray.length > smallFiles.length) {
        showInfo(t("Please select images smaller than 10MB!"));
      }

      let compressedImages: File[] = [];

      filesArray.forEach((file) => {
        new Compressor(file, {
          quality: 0.6,
          maxWidth: 1200,
          maxHeight: 1200,
          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
            if (result instanceof Blob) {
              let resultFile = new File([result], file.name);
              compressedImages.push(resultFile);
              setImages((prev: File[]) => [...prev, resultFile]);
            } else {
              compressedImages.push(result);
              setImages((prev: File[]) => [...prev, result]);
            }
          },
          error(err) {
            console.log(err.message);
            setImages((prev: File[]) => [...prev, file]);
          },
        });
      });
    }
  };

  return (
    <>
      <div className="flex justify-center">
        <input
          type="file"
          id={idRef.current}
          ref={inputRef}
          className="file-input"
          multiple={multiple}
          accept="image/*"
          onChange={imageOnchange}
          required={required && images.length === 0}
          capture="environment"
        />
        <label htmlFor={idRef.current} style={required ? {'color': 'red'} : {}}>
          {title ?? t("Take a picture")}
          <i className="icon-camera"></i>
        </label>
      </div>

      {preview ? (
        <div className="row mb-3">
          {Array.from(images)?.map((image, index) => (
            <div
              className="flex justify-center relative"
              style={{ width: "50%", margin: "auto" }}
              key={index}
              onClick={() => removeImage(index)}
            >
              <div className="picture-preview">
                <button className="button--delete" type="button">
                  &times;
                </button>
                <img
                  className="mt-4 mb-3"
                  src={URL.createObjectURL(image)}
                  alt="Selected file"
                />
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default ImageInput;
